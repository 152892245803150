<template>
    <div id="advice">
        <div class="admin_main_block">
            <div class="user_temp_title">反馈列表</div>
    
            <el-table :data="list" v-loading = 'loading'>
                <el-table-column label="建议类型" prop="suggest_type" align="center">
                    <template slot-scope="scope" >
                        <span v-if="scope.row.suggest_type === 1">意见反馈</span>
                        <span v-if="scope.row.suggest_type === 2">功能建议</span>
                        <span v-if="scope.row.suggest_type === 3">客服投诉</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="反馈内容" align="center"></el-table-column>
                <el-table-column label="反馈图片"  align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.imgs!=null">
                            <el-image :src="$common.getImgUrl(scope.row.imgs.split(',')[0])" :preview-src-list="$common.getImgUrls(scope.row.imgs.split(','))" style="width:100px; height:100px">
                            </el-image>
                        </div>
                        
                    </template>
                </el-table-column>
                <el-table-column prop="reply" label="回复内容" align="center"  show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.reply || '--'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" width="120" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0" class="error">未回复</span>
                        <span v-if="scope.row.status == 1" class="success">已回复</span>
                        <span v-if="scope.row.status == 2" class="success">已回复</span>
                        <i class="tip" v-if="scope.row.status == 1"></i>
                    </template>
                </el-table-column>
                 <el-table-column prop="create_time" label="反馈内容" align="center"></el-table-column>
            </el-table>
            <div class="home_fy_block">
                <el-pagination
                        v-if="total>0"
                        background
                        @current-change="handleCurrentChange"
                        :current-page="searchData.pageNo"
                        :page-size="searchData.pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
            

        </div>
    </div>
</template>

<script>
    import Upload from '@/components/upload/Upload'
    export default {
        name: '',
        data() {
            return {
                
                loading:false,//防重复点击
               
                searchData:{
                    id:localStorage.getItem('ids'),
                    tenantId:localStorage.getItem('tenantInfoid'),
                    pageNo:1,
                    pageSize:10,
                },
 
                list:[],
                total:0,
            }
        },
        created(){
            this.getList();   
        },
        mounted(){},
        components: {
			Upload
		},
        watch: {},
        methods: {
            handleCurrentChange(val){
                this.searchData.pageNo = val;
                this.getList();
            },
            getList(){
                this.loading = true;
                this.$request.post({
                    url:'/ReportApi/business/addAdvicelist',
                    params:this.searchData,
                    success: res => {
                        this.list = res[0];
                        this.total = res[1];
                    },
                    finally: () => {
                        this.loading = false;
                    }
                })
            },

        }
    }
</script>

<style lang="scss" scoped>
    #advice{
        .tip {
            display:block;
            background:#f00;
            border-radius:50%;
            width:8px;
            height:8px;
            top:18px;
            right:25px;
            position:absolute;
        }
        .admin_main_block{
            min-height: 855px;
            background: #fff;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-radius: 4px;
            .user_temp_title{
                margin: auto;
                padding-left: 20px;
                border-bottom: 1px solid #eeeeee;
                padding-bottom: 20px;
                height: 25px;
                margin-bottom: 20px;
                font-weight: bold;
            }
            .admin_form_main{
                .el-form-item{
                    width: 600px;
                }
            }
            .recharge_main{
                padding: 0 30px;
                font-size: 12px;
                .user_money{
                    font-size: 14px;
                    line-height: 45px;
                }
                .chose_pay_type{
                    margin-top: 30px;
                    .pay_type{
                        div{
                            position: relative;
                            width: 100px;
                            text-align: center;
                            display: inline-block;
                            font-size: 16px;
                            padding: 10px 15px;
                            border: 1px solid #F80;
                            border-radius: 4px;
                            margin-right: 5px;
                            cursor: pointer;
                        }
                        img{
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 20px;
                        }
                    }
                    .btn{
                        margin-left: 137px;
                        margin-top: 20px;
                        width: 292px;
                        border: 0px;
                        background-color: #f80;
                        text-align: center;
                        line-height: 40px;
                        border-radius: 4px;
                        color: #FFFFFF;
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
                .recharge_num{
                    font-size: 18px;
                    margin-top: 30px;
                    line-height: 30px;
                    input{
                        width: 270px;
                        border: 1px solid #ccc;
                        border-radius: 6px;
                        height: 36px;
                        outline: none;
                        padding: 0 10px;
                        font-size:  20px;
                        margin-left: 15px;
                        margin-right: 10px;
                        text-align: left;
                    }
                }
                .recharge_img{
                    margin-left: 127px;
                    margin-top: 50px;
                    img{
                        width: 200px;
                    }
                }
                .tip{
                    color: #fd0004;
                    background: #ffefe2;
                    padding: 18px 22px;
                    line-height: 30px;
                    margin-top: 20px;
                }
            }
            .admin_table_main{
                .edit_password_from{
                    margin-top: 40px;
                    padding-bottom: 30px;
                    padding-left: 70px;
                    .item{
                        line-height: 40px;
                        margin-bottom: 30px;
                        clear: both;
                        label{
                            margin-right: 10px;
                            font-size: 14px;
                        }
                        input{
                            width: 272px;
                            height: 40px;
                            padding: 0 10px;
                            border: 1px solid #ddd;
                            border-radius: 6px;
                            box-sizing: border-box;
                            outline: none;
                        }
                    }
                    .btn{
                        width: 270px;
                        border: 0px;
                        background-color: #f80;
                        text-align: center;
                        line-height: 40px;
                        border-radius: 4px;
                        color: #FFFFFF;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 68px;
                    }
                }
            }
        }
        .home_fy_block{
            margin-top: 30px;
            text-align: right;
            margin-right: 10px;
        }
    }
</style>