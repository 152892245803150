<template>
    <div id="advice">
        <div class="admin_main_block">
            <div class="user_temp_title">意见反馈</div>
            
            <div class="admin_form_main">
                <el-form :model="formData" label-width="100px" :rules="rules" ref="formData">
                    <el-form-item label="反馈类型" prop="suggestType" size="small">
                        <el-radio-group v-model="formData.suggestType">
                            <el-radio :label="1">意见反馈</el-radio>
                            <el-radio :label="2">功能建议</el-radio>
                            <el-radio :label="3">客服投诉</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item  label="投诉类型" v-if="formData.suggestType == 3">
                        <el-select v-model="complaintType" placeholder="投诉类型" @change="fixText()" clearable>
                            <el-option :value="item.value" :label="item.label" v-for="(item, index) in complaintTypeList"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="反馈内容" prop="content" size="small">
                        <el-input v-model="formData.content" size="small" maxlength="100" show-word-limit :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" placeholder="请输入你的问题"></el-input>
                    </el-form-item>
                    <el-form-item prop="imgs" label="添加图片">
                        <Upload
                        ref="imgs"
                        :bigBox="true"
                        :isMultiple="true"
                        :limit_size="3"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" type="primary" @click="add">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>
    </div>
</template>

<script>
    import Upload from '@/components/upload/Upload'
    export default {
        name: '',
        data() {
            return {
                
                loading:false,//防重复点击
               
                searchData:{
                    pageNo:1,
                    pageSize:10,
                },
                
                formData:{
                    suggestType: 1,
                    content:'',
                    imgs:''
                },
                rules:  {
                    suggestType: [
                        { required: true, message: '反馈类型不能为空', trigger: 'blur' },
                    ],
                    content: [
                        { required: true, message: '反馈内容不能为空', trigger: 'blur' },
                    ],
                    
                },
                complaintType:'',
                complaintTypeList: [
					{
						value: 1,
						label: '态度恶劣'
					},
					{
						value: 2,
						label: '长时间不回复'
					},
					{
						value: 3,
						label: '其他'
					}
				],
            }
        },
        created(){},
        mounted(){},
        components: {
			Upload
		},
        watch: {},
        methods: {
            fixText(){
                if(this.complaintType != 3){
                    this.formData.content = this.complaintTypeList[this.complaintType - 1].label
                }else{
                    this.formData.content = '';
                }
            },
            add(){
                if(this.$refs.imgs.path_list.length > 0){
                    var imgList = [];
                    for(var i=0; i < this.$refs.imgs.path_list.length; i++){
                        imgList.push(this.$refs.imgs.path_list[i].path);
                    }
                    this.formData.imgs = imgList;
                }
                this.$refs['formData'].validate((valid) => {
                    if (valid) {
                        if(this.loading) return;
                        this.loading = true;
                        const loading = this.$loading();
                        console.log(this.formData)
                        this.formData.usId=localStorage.getItem('ids'),
                        this.formData.tenantId=localStorage.getItem('tenantInfoid'),
                        this.$request.post({
                            url:'/ReportApi/business/addAdvice',
                            params: {
                                usId:this.formData.usId,
                                tenantId: this.formData.tenantId,
                                suggestType:this.formData.suggestType,
                                content:this.formData.content,
                                imgs:this.formData.imgs
                            },
                            success: res => {
                                this.$message.success('提交成功');
                                this.reset();
                            },
                            finally: () => {
                                this.loading = false;
                                loading.close();
                            }
                        })
                    } else {
                        //consloe.log('error submit!!');
                        return false;
                    }
                });
            },
            reset(){
                this.$refs.imgs.removeAll();
                this.formData = {
                    suggestType: 1,
                    content:'',
                    imgs:''
                }
            },

        }
    }
</script>

<style lang="scss" scoped>
    #advice{
        .admin_main_block{
            min-height: 855px;
            background: #fff;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-radius: 4px;
            .user_temp_title{
                margin: auto;
                padding-left: 20px;
                border-bottom: 1px solid #eeeeee;
                padding-bottom: 20px;
                height: 25px;
                margin-bottom: 20px;
                font-weight: bold;
            }
            .admin_form_main{
                .el-form-item{
                    width: 600px;
                }
            }
            .recharge_main{
                padding: 0 30px;
                font-size: 12px;
                .user_money{
                    font-size: 14px;
                    line-height: 45px;
                }
                .chose_pay_type{
                    margin-top: 30px;
                    .pay_type{
                        div{
                            position: relative;
                            width: 100px;
                            text-align: center;
                            display: inline-block;
                            font-size: 16px;
                            padding: 10px 15px;
                            border: 1px solid #F80;
                            border-radius: 4px;
                            margin-right: 5px;
                            cursor: pointer;
                        }
                        img{
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 20px;
                        }
                    }
                    .btn{
                        margin-left: 137px;
                        margin-top: 20px;
                        width: 292px;
                        border: 0px;
                        background-color: #f80;
                        text-align: center;
                        line-height: 40px;
                        border-radius: 4px;
                        color: #FFFFFF;
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
                .recharge_num{
                    font-size: 18px;
                    margin-top: 30px;
                    line-height: 30px;
                    input{
                        width: 270px;
                        border: 1px solid #ccc;
                        border-radius: 6px;
                        height: 36px;
                        outline: none;
                        padding: 0 10px;
                        font-size:  20px;
                        margin-left: 15px;
                        margin-right: 10px;
                        text-align: left;
                    }
                }
                .recharge_img{
                    margin-left: 127px;
                    margin-top: 50px;
                    img{
                        width: 200px;
                    }
                }
                .tip{
                    color: #fd0004;
                    background: #ffefe2;
                    padding: 18px 22px;
                    line-height: 30px;
                    margin-top: 20px;
                }
            }
            .admin_table_main{
                .edit_password_from{
                    margin-top: 40px;
                    padding-bottom: 30px;
                    padding-left: 70px;
                    .item{
                        line-height: 40px;
                        margin-bottom: 30px;
                        clear: both;
                        label{
                            margin-right: 10px;
                            font-size: 14px;
                        }
                        input{
                            width: 272px;
                            height: 40px;
                            padding: 0 10px;
                            border: 1px solid #ddd;
                            border-radius: 6px;
                            box-sizing: border-box;
                            outline: none;
                        }
                    }
                    .btn{
                        width: 270px;
                        border: 0px;
                        background-color: #f80;
                        text-align: center;
                        line-height: 40px;
                        border-radius: 4px;
                        color: #FFFFFF;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 68px;
                    }
                }
            }
        }
        .home_fy_block{
            margin-top: 30px;
            text-align: right;
            margin-right: 10px;
        }
    }
</style>