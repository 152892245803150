<template>
    <div id="receive_order">
        <div class="admin_main_block">
            <div class="user_temp_title">申请提现</div>
            <div class="admin_form_main" style="float: left;">
                <div class="daifatu_home">
                    <el-form label-width="100px">
                    <el-form-item label="提现类型：">
                        <el-select v-model="formData.type" size="small">
                            <!-- <el-option label="银行卡提现" :value="1"></el-option> -->
                            <el-option label="支付宝提现" :value="2"></el-option>
                            <!-- <el-option label="微信提现" :value="3"></el-option> -->
                        </el-select>
                    </el-form-item>
                    <el-form-item label="可提现金额:">
                        <el-input disabled v-model="balance" size="small"></el-input>
                    </el-form-item>
                    <template v-if="formData.type == 1">
                        <el-form-item label="选择银行：">
                            <el-select v-model="formData.bankName" size="small">
                                <el-option v-for="item in bankList" :label="item.name" :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="formData.bankName == '其他'" label="银行名称：" size="small">
                            <el-input v-model="formData.otherBankName" placeholder="请输入银行名称"></el-input>
                        </el-form-item>
                        <el-form-item label="银行卡号：">
                            <el-input v-model="formData.bankNum" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="真实姓名：">
                            <el-input v-model="formData.realName" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="提现金额：">
                            <el-input v-model="formData.money" @blur="money()" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="登录密码：">
                            <el-input v-model="formData.password" type="password" size="small"></el-input>
                        </el-form-item>
                    </template>
                    <template v-else>
                        <el-form-item label="真实姓名：" v-if="formData.type == 2">
                            <el-input v-model="formData.realName"></el-input>
                        </el-form-item>
                        <el-form-item label="支付宝账号：" v-if="formData.type == 2">
                            <el-input v-model="formData.account"></el-input>
                        </el-form-item>
                        <el-form-item label="提现金额：">
                            <el-input v-model="formData.money" @blur="money()"  size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="登录密码：">
                            <el-input v-model="formData.password" type="password" size="small"></el-input>
                        </el-form-item>
                    </template>
                        <el-form-item size="small">
                            <el-button type="primary" size="small" @click="add">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="admin_form_main" style="float:right" v-if="formData.type == 3">
                <div class="daifatu_home">
                    <el-form label-width="125px">
                        <el-form-item label="请用微信扫二维码进行授权（将提现到此微信）：">
                            <img :src="qrlink" alt="">
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" v-loading = 'loading'>
                    <el-table-column align="center" label="提现类型">
                <template slot-scope="scope">
                    <span v-if="scope.row.type == 1">银行卡</span>
                    <span v-if="scope.row.type == 2">支付宝</span>
                    <span v-else>微信</span>
                </template>
                </el-table-column>
                <!-- <el-table-column align="center" label="所属银行">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">{{scope.row.bankName}}</span>
                        <span v-else>--</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="银行卡号">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">{{scope.row.bankNum}}</span>
                        <span v-else>--</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="支付宝账号">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 2">{{scope.row.account}}</span>
                        <span v-else>--</span>
                    </template>
                </el-table-column> -->
                
                <el-table-column align="center" label="提现金额" prop="money"></el-table-column>
                <!-- <el-table-column align="center" label="真实姓名" prop="realName"></el-table-column> -->
                <el-table-column align="center" label="审核状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status===1">通过</span>
                        <span v-if="scope.row.status===0">审核中</span>
                        <span v-if="scope.row.status===-1">不通过</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="不通过理由" prop="reason"></el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{$common.getDate(scope.row.createTime)}}
                    </template>
                </el-table-column>
                </el-table>
                <div class="home_fy_block">
                    <el-pagination 
                        v-if="total>0"
                        background 
                        @current-change="handleCurrentChange" 
                        :current-page="searchData.pageNo" 
                        :page-size="searchData.pageSize" 
                        layout="total, prev, pager, next, jumper" 
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import rsa from '@/utils/rsa'
import QRCode from 'qrcode';
export default {
    name: '',
    data() {
        return {
            loading:false,//防重复点击
            searchData:{
                pageNo:1,
                pageSize:10,
            },
            list:[],
            total:0,

            formData:{
                type:2,
                bankName: '',
                bankNum: '',
                realName: '',
                money: '',
                password: '',
            },
            dialogVisible:false,
            total: 100,
            statusList: [
                {
                    value: 1,
                    label: '通过'
                },
                {
                    value: 0,
                    label: '审核中'
                },
                {
                    value: -1,
                    label: '不通过'
                }
            ],
            typeList: [
                // {
                // 	label: '银行卡提现',
                // 	value: 1
                // },
                // {
                // 	label: '支付宝提现',
                // 	value: 2
                // },
                {
                    label: '微信提现',
                    value: 3
                }
            ],
            bankList: [
                {
                    name: '中国工商银行'
                },
                {
                    name: '中国农业银行'
                },
                {
                    name: '中国建设银行'
                },
                {
                    name: '中国银行'
                },      
                {
                    name: '招商银行'
                },
                {
                    name: '交通银行'
                },
                {
                    name: '光大银行'
                },
                {
                    name: '中信银行'
                },
                {
                    name: '华夏银行'
                },
                {
                    name: '民生银行'
                },
                {
                    name: '广发银行'
                },
                {
                    name: '兴业银行'
                },
                {
                    name: '浦发银行'
                },
                {
                    name: '其他'
                },
            ],
            balance:'',
        }
    },
    created(){
        this.getBalance();
        this.qrcode();
        this.getList();
    },
    mounted(){},
    watch: {},
    methods: {
        getBalance(){
            this.$request.post({
                url:'/bus/info/getBalance',
                success: res => {
                    this.balance = res;
                }
            })
        },
        //生成二维码
        qrcode() {
            let str = location.protocol + '//' + location.host + '/business/wxLogin';
            QRCode.toDataURL(str)
            .then(url => {
                this.qrlink = url;
            })
        },
        //---------判断非负数----------
        money:function(){
            var reg = /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/;
            if(!reg.test(this.formData.money)){
                this.formData.money = '';
            }else if(this.formData.money <= 1){
                // this.formData.money = '';
            }else if(this.formData.money > this.info.balance){
                this.formData.money = this.info.balance
            }else{
                this.formData.money = Number(this.formData.money).toFixed(2);
            }
        },
        add(){
            if(this.loading) return;
            this.loading = true;
            const loading = this.$loading();
            let formData = {}
            if(this.formData.type == 1){
                let bankName = ''
                if(!this.formData.bankName){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请选择银行')
                    return
                }
                bankName = this.formData.bankName
                if(this.formData.bankName=='其他'){
                    bankName = undefined
                    if(!this.formData.otherBankName){
                        this.loading = false;
                        loading.close();
                        this.$message.error('请输入银行名称')
                        return
                    }
                }
                if(!this.formData.bankNum){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入银行卡号')
                    return
                }
                if(!this.formData.realName){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入真实姓名')
                    return
                }
                if(!this.formData.money){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入提现金额')
                    return
                }
                if(!this.formData.password){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入密码')
                    return
                }
                formData = {
                    type: 1,
                    bankNum: this.formData.bankNum,
                    realName: this.formData.realName,
                    money: this.formData.money,
                    password: rsa.encryptByPublicKey(this.formData.password),
                    bankName,
                }
            }else if(this.formData.type==2){
                if(!this.formData.realName){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入真实姓名')
                    return
                }
                if(!this.formData.account){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入支付宝账号')
                    return
                }
                if(!this.formData.money){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入提现金额')
                    return
                }
                if(!this.formData.password){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入密码')
                    return
                }
                formData = {
                    type: 2,
                    realName: this.formData.realName,
                    money: this.formData.money,
                    password: rsa.encryptByPublicKey(this.formData.password),
                    account:this.formData.account
                }
            }else{
                if(!this.formData.money){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入提现金额')
                    return
                }
                if(!this.formData.password){
                    this.loading = false;
                    loading.close();
                    this.$message.error('请输入密码')
                    return
                }
                formData = {
                    type: 3,
                    money: this.formData.money,
                    password: rsa.encryptByPublicKey(this.formData.password),
                }
            }
            if (this.formData.money < 1){
                this.loading = false;
                loading.close();
                this.$message.error('提现金额不能少于1元')
                return;
            }
            this.$request.post({
                url: '/bus/withdrawals/add',
                params:formData,
                success:res => {
                    this.$message.success('操作成功')
                    this.dialogVisible = false
                    this.getList()
                    this.getBalance()
                    this.formData = {
                        type:3, 
                    }
                },
                finally: () => {
                    this.loading = false;
                    loading.close();
                }
            })
        },
        getList(){
            this.loading = true;
            this.$request.post({
                url:'/bus/withdrawals/list',
                params:this.searchData,
                success: res => {
                    this.list = res.list;
                    this.total = res.total;
                },
                finally: () => {
                    this.loading = false;
                }
            })
        },
         handleCurrentChange(val){
            this.searchData.pageNo = val;
            this.getList();
        },
    }
}
</script>

<style lang="scss" scoped>
#receive_order{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        min-height: 855px;
        border-radius: 4px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .admin_form_main{
            .el-form-item{
                width: 400px;
                margin-bottom: 18px;
            }
        }
    }
    .home_fy_block{
        margin-top: 30px;
        text-align: right;
        margin-right: 10px;
    }
}
</style>